import { ErrorPageProps } from '../../types/components/shared/ErrorPage';

const ErrorPage: React.FC<any> = (props: ErrorPageProps) => {
  const { errorCode, errorMessage } = props;

  return (
    <div className="missing-page text-white h-screen flex items-center justify-center bg-gray-900">
      <div className="flex flex-col md:flex-row items-center gap-4">
        <div className="text-[72px]" cy-test-id="error-code">{ errorCode }</div>
        <p className="w-[360px] text-gray-600" cy-test-id="error-message">{ errorMessage }</p>
      </div>
    </div>
  );
};

export default ErrorPage;
