import React from 'react';
import Layout from '../layout/Layout';
import { AppProps } from '../types/pages/index';
import { seo } from '../staticData/Seo';

import ErrorPage from '../components/shared/ErrorPage';

const Custom404: React.FC<AppProps> = () => {
  const headContent = {
    title: `The world's largest library of production-quality VFX assets`,
    keywords: seo.keywords,
    description: seo.description,
    og: seo.og
  };

  return (
    <Layout head={headContent}>
      <ErrorPage
        errorCode={404}
        errorMessage="The page you were looking for doesn't exist. You may have mistyped the address or the page may have moved."
      />
    </Layout>
  );
};

export default Custom404;
